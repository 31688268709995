import mitt from 'mitt'
import type { NotifiableError } from '@bugsnag/js'
import type { Dict } from 'mixpanel-browser'
import type {
  Invoice,
  SelectedPricingType,
  IBank,
  BillStatusType,
  Beneficiary,
} from '@/shared/interfaces'
import type { GetBillsQueryParams } from '~/types/apiPayload/bills.payload'

export interface Data {
  trigger: boolean
  billPageStatus?: BillStatusType
  bill?: Invoice
  bills?: (Invoice | null)[]
  duplicateBillId?: string
  paymentType?: string
  selectedCard?: string
  pricing?: SelectedPricingType
  paymentDate?: string
  selectBank?: IBank
  updateVendorType?: {
    type: 'create' | 'update'
    beneficiary: Beneficiary | null
  }
  createNewVendorForBill?: boolean
  paymentScheduleDate?: string
}

type ApplicationEvents = {
  'open:vendor': Data
  'open:lenkie-wraps': boolean
  'close:vendor': Data
  'load:vendor': Data
  'fetch:bills': {
    trigger: boolean
    params?: GetBillsQueryParams
    goToTab?: 'Unpaid' | 'Awaiting Approval' | 'Scheduled' | 'Paid'
  }
  otp: Data
  'create-vendor': Data
  'bill:payment': Data
  'bulk-bills:payment': Data
  'bill:edit': Data
  'bill:go-to-page': Data
  'bill:duplicate': Data
  'bill-table:duplicate': Data
  'bill:removeBillFromBulk': Data
  'open:sidebar': Data
  'select:card': Data
  'price:gnpl': Data
  'bank:added': IBank
  'select:bank': Data
  'close:modal': Data
  'resend:otp': Data
  'schedule:gnpl': Data
  'error:report': NotifiableError
  'update:credit-facility': null
  'fetch:bills-unreconciled': boolean
  'open:bill-summary': {
    disablePayment: boolean
    showReconcileButton?: boolean
  }
  'track:mixpanel': { event: string; data: Dict }
  'open:add-payroll-employee': null
  'fetch:payroll-records': null
  'toggle:bulkbillsModal': { isOpen: boolean }
  'fetch:team-members': null
  'reload:bill-summary': null
}

export default defineNuxtPlugin({
  name: 'eventBus-plugin',
  setup() {
    const emitter = mitt<ApplicationEvents>()

    return {
      provide: {
        event: emitter.emit, // Will emit an event
        listen: emitter.on, // Will register a listener for an event
      },
    }
  },
})
