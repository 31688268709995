import type { ErrorObject } from '@vuelidate/core'
import { clsx, type ClassValue } from 'clsx'
import type { AxiosError, AxiosInstance } from 'axios'
import dayjs from 'dayjs'
import { twMerge } from 'tailwind-merge'
import Cleave from 'cleave.js'
import type { CleaveOptions } from 'cleave.js/options'
import type { Beneficiary, BillStatusType } from '~/shared/interfaces'
import type { UserDetailsFromToken } from '~/types/models/user.model'
import EuroFlagSvg from '@/assets/flags/eur.svg'
import GbFlagSvg from '@/assets/flags/gb.svg'
import UsdFlagSvg from '@/assets/flags/usd.svg'
import type { GetMeResponseV2 } from '~/types/apiResponse/me.response'
import BaseInput from '~/components/Base/BaseInput.vue'
import { useAuthService } from '~/services/auth'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatDate(date: string | Date, format = 'DD/MM/YYYY') {
  return dayjs(date).format(format)
}

export async function logout() {
  await useAuthService().logout()
}

export function getFileType(invoiceStr: string) {
  const strArr = invoiceStr.split('.')
  const type = strArr[strArr.length - 1].toLowerCase()
  switch (type) {
    case 'pdf':
      return 'application/pdf'
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    case 'png':
      return 'image/png'
    case 'jpeg':
    case 'jpg':
      return 'image/jpeg'
    case 'csv':
      return 'text/csv'
    case 'xls':
      return 'application/vnd.ms-excel'
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    case 'txt':
      return 'text/plain'
    case 'zip':
      return 'application/zip'
    default:
      return 'none'
  }
}

export function getLastItemInArray<T>(arr: T[]): T | undefined {
  return arr.length > 0 ? arr[arr.length - 1] : undefined
}

export function getUserDetailsFromToken(token: string) {
  try {
    return JSON.parse(atob(token.split('.')[1])) as UserDetailsFromToken
  } catch (e) {
    return null
  }
}

export const vuelidateErrorMessage = (errors: ErrorObject[]) => {
  return errors.length ? (errors[0].$message as string) : ''
}

export function getAccountNumber(vendor: Beneficiary) {
  const account = vendor.account
  if (!account) return ''
  const { currency } = account

  if (!currency) {
    return ''
  }

  switch (currency.code) {
    case 'GBP':
      return account.uk_bank_details?.account_number || ''
    case 'EUR':
      return account.international_bank_account_details?.iban || ''
    case 'USD':
      if (account.ach_bank_details?.account_number) {
        return account.ach_bank_details.account_number
      } else if (account.fedwire_bank_account_details?.account_number) {
        return account.fedwire_bank_account_details.account_number
      } else {
        return account.international_bank_account_details?.iban || ''
      }
    default:
      return ''
  }
}

export function getSortCode(vendor: Beneficiary) {
  const account = vendor.account
  if (!account) return ''
  const { currency } = account

  if (!currency) {
    return ''
  }

  switch (currency.code) {
    case 'GBP':
      return formatSortCode(account.uk_bank_details?.sort_code) || ''
    case 'EUR':
      return account.international_bank_account_details?.swift_code || ''
    case 'USD':
      if (account.ach_bank_details?.account_number) {
        return account.ach_bank_details.swift_code
      } else if (account.fedwire_bank_account_details?.account_number) {
        return account.fedwire_bank_account_details.routing_number
      } else {
        return account.international_bank_account_details?.swift_code || ''
      }
    default:
      return ''
  }
}

export function getFlag(code: Beneficiary['account']['currency']['code']) {
  const flagMap: Record<typeof code, string> = {
    EUR: EuroFlagSvg,
    GBP: GbFlagSvg,
    USD: UsdFlagSvg,
  }
  return flagMap[code]
}

export function isLastInArray<T>(array: T[], index: number) {
  return array.length - 1 === index
}

export const LeadBoosterController = {
  hide() {
    const element = document.getElementById(
      'LeadboosterContainer',
    ) as HTMLElement
    element?.style.setProperty('display', 'none', 'important')
  },
  show() {
    const element = document.getElementById(
      'LeadboosterContainer',
    ) as HTMLElement
    element?.style.setProperty('display', '', 'important')
  },
}

export const isElementLoaded = async (selector: string) => {
  while (!document.querySelector(selector))
    await new Promise(requestAnimationFrame)
}

export const getFileUrl = (file: File) => {
  return URL.createObjectURL(file)
}

export const fetchInvoiceFiles = async ({
  filesStr,
  sellerId = '',
  lenkieBankingApi,
}: {
  filesStr: string[]
  sellerId: string
  lenkieBankingApi: AxiosInstance
}) => {
  try {
    const uploadedFiles = await Promise.all(
      filesStr.map(async (el) => {
        const fileName = el
          .split(`organisations/${sellerId}/bill/invoices/`)
          .join('')
        const type = getFileType(el)

        try {
          const response = await lenkieBankingApi.get(
            `/FileUpload/downloadFile?filePath=${el}`,
            {
              responseType: 'blob',
            },
          )
          const blob = new Blob([response.data], {
            type: response.headers['content-type'],
          })
          return new File([blob], fileName, {
            type,
          })
        } catch (error) {
          return null
        }
      }),
    )
    return uploadedFiles.filter((file) => file !== null)
  } catch (error) {
    return []
  }
}

export const downloadFile = (file: File | null) => {
  if (file != null) {
    const link = document.createElement('a')
    link.setAttribute('download', file.name)
    link.href = URL.createObjectURL(file)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
}

export function generateSixDigitNumber() {
  const randomArray = new Uint32Array(1)
  window.crypto.getRandomValues(randomArray)
  const randomNumber = randomArray[0] % 1000000 // Ensure the number is within the range [0, 999999]
  return +randomNumber.toString().padStart(6, '0') // Ensure the number is six digits long
}

function markdownifyObject(obj: { [key: string]: any }): string {
  if (obj === null || obj === undefined || Array.isArray(obj)) {
    return ''
  }

  if (typeof obj === 'string') {
    return obj
  }

  let markdownString = ''

  function processObject(o: { [key: string]: any }, indent: number = 0): void {
    const indentation = ' '.repeat(indent * 2)
    for (const key in o) {
      // eslint-disable-next-line no-prototype-builtins
      if (o.hasOwnProperty(key)) {
        const value = o[key]
        if (
          typeof value === 'object' &&
          value !== null &&
          !Array.isArray(value)
        ) {
          markdownString += `${indentation}*${key}*:\n`
          processObject(value, indent + 1)
        } else if (Array.isArray(value)) {
          markdownString += `${indentation}*${key}*:\n`
          value.forEach((item) => {
            if (typeof item === 'object' && item !== null) {
              markdownString += `${indentation}-\n`
              processObject(item, indent + 1)
            } else {
              markdownString += `${indentation}- ${item}\n`
            }
          })
        } else {
          markdownString += `${indentation}*${key}*: ${value}\n`
        }
      }
    }
  }

  processObject(obj)
  return markdownString
}

export function buildErrorStringForBugsnagForNetworkRequests(payload: {
  error: AxiosError
  personId?: string
  profile?: GetMeResponseV2 | null
  organisation?: GetMeResponseV2['organisations'][number] | null
}): string {
  const { error, profile, organisation, personId } = payload
  const router = useRouter()

  const companyName = organisation?.trading_name || '-'
  const companyId = organisation?.id || '-'
  const email = profile?.work_email_address || '-'
  const fullName = `${profile?.given_name || '-'} ${profile?.family_name || '-'}`

  return `
*API Endpoint*: ${error.response?.config.url}
*Method*: ${error?.config?.method?.toUpperCase()}
*Status Code*: ${error.status}
*Current Page*: ${router.currentRoute.value.fullPath}

*User Details*
- *Email*: ${email}
- *Name*: ${fullName}
- *Person Id*: ${personId}

*Company Details*
- *Name*: ${companyName}
- *Company Id*: ${companyId}

\n
${markdownifyObject(error?.response?.data as any)}
\n
===================================

*Experimental:*
<${generateClarityVideoUrl(email)}|Clarity link>
`
}

export function addErrorMetadataToBugsnagEventForNetworkError(
  error: AxiosError,
) {
  const metadata: Record<string, any> = {}

  if (error?.config?.data) {
    if (error?.config?.method !== 'get') {
      try {
        metadata.payload = JSON.parse(error.config.data)
      } catch (e) {
        metadata.payload = null
      }
    }
  }

  if (error?.response?.data) {
    metadata.response = error.response.data
  }
  return metadata
}

function generateClarityVideoUrl(email: string) {
  const {
    $config: {
      public: { CLARITY },
    },
  } = useNuxtApp()
  const baseUrl = `https://clarity.microsoft.com/projects/view/${CLARITY}/impressions`
  const startTimestamp = dayjs().startOf('day').toDate().getTime()
  const endTimestamp = dayjs().endOf('day').toDate().getTime()
  const encodedEmail = encodeURIComponent(`email:${email}`)
  // Constructing the URL with parameters
  const url = `${baseUrl}?date=Custom&start=${startTimestamp}&end=${endTimestamp}&Variables=${encodedEmail}`
  return url
}

export function initializeInputsForVCleave(
  component: Ref<InstanceType<typeof BaseInput> | undefined>,
  mask: CleaveOptions,
) {
  if (component.value && component.value.inputRef) {
    const inputRef = component.value.inputRef
    return new Cleave(inputRef, mask)
  }
}

export function getFileNameAndExtension(fileName: string): [string, string] {
  // Find the position of the last dot in the fileName
  const lastDotIndex = fileName.lastIndexOf('.')

  // Handle cases where there's no dot or the dot is at the beginning
  if (
    lastDotIndex === -1 ||
    lastDotIndex === 0 ||
    lastDotIndex === fileName.length - 1
  ) {
    return [fileName, ''] // Return the whole fileName and an empty extension
  } else {
    // Extract the name and extension
    const name = fileName.slice(0, lastDotIndex)
    const extension = fileName.slice(lastDotIndex + 1)
    return [name, extension]
  }
}

export function formatSortCode(sortCode?: string) {
  if (!sortCode) return ''
  sortCode = sortCode.replace(/\W/g, '')
  const matchResult = sortCode.match(/.{1,2}/g)
  if (matchResult === null) return ''
  const formattedSortCode = matchResult.join('-')
  return formattedSortCode
}

export const statusColors = {
  Draft: { text: 'text-yellow-700', bg: 'bg-yellow-400', value: 'Draft' },
  ReadyForPayment: {
    text: 'text-blue-700',
    bg: 'bg-blue-400',
    value: 'Ready for payment',
  },
  Failed: {
    text: 'text-red-700',
    bg: 'bg-red-400',
    value: 'Failed',
  },
  AwaitingApproval: {
    text: 'text-[#f9f9f9]',
    bg: 'bg-[#F97316]',
    value: 'Awaiting approval',
  },
  Approved: {
    text: 'text-lime-700',
    bg: 'bg-lime-400',
    value: 'Approved',
  },
  Canceled: {
    text: 'text-slate-700',
    bg: 'bg-slate-400',
    value: 'Canceled',
  },
  Rejected: {
    text: 'text-red-700',
    bg: 'bg-red-400',
    value: 'Rejected',
  },
  Scheduled: {
    text: 'text-purple-700',
    bg: 'bg-purple-400',
    value: 'Scheduled',
  },
  Paid: {
    text: 'text-green-700',
    bg: 'bg-green-400',
    value: 'Paid',
  },
  Processing: {
    text: 'text-sky-700',
    bg: 'bg-sky-400',
    value: 'Processing',
  },
  None: {
    text: 'text-gray-700',
    bg: 'bg-gray-400',
    value: 'None',
  },
}

export function getBillStatusClasses(billStatus: BillStatusType | undefined) {
  const statusInfo = statusColors[billStatus || 'None']
  return `${statusInfo.text} ${statusInfo.bg}`
}

export const fileUploadRejectionReason = {
  'file-invalid-type':
    'The file type is not supported. Please upload a valid file type.',
  'file-too-large':
    'The file is too large. Please upload a file smaller than the maximum allowed size.',
  'file-too-small':
    'The file is too small. Please upload a file that meets the minimum size requirement.',
  'too-many-files':
    'Too many files were uploaded. Please reduce the number of files and try again.',
}

export const formatNumberWithCommas = (number: string) => {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const copyString = async (
  str: string,
  config?: { onSuccess?: () => void; onError?: () => void },
) => {
  try {
    await navigator.clipboard.writeText(str)
    if (config?.onSuccess) {
      config.onSuccess()
    }
  } catch {
    if (config?.onError) {
      config.onError()
    }
  }
}

export function insertInvisibleElementWithIdForPendo(id: string) {
  const element = document.createElement('div')
  element.id = id
  // element.style.display = 'none'
  document.body.appendChild(element)

  setTimeout(() => {
    element.click()
  }, 1000)
}

export function formatMoney(value: number, options?: Intl.NumberFormatOptions) {
  return Intl.NumberFormat('en-GB', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  }).format(value)
}

export function formatMoneyWithCurrency(
  value: number,
  options: Intl.NumberFormatOptions & { locale?: string } = {},
) {
  const { locale, ...rest } = options
  return Intl.NumberFormat(locale || 'en-GB', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'GBP',
    currencySign: 'accounting',
    ...rest,
  }).format(value)
}

export const formatFinancialField = (value: unknown, returnWhenEmpty = '-') => {
  return typeof value === 'number'
    ? formatMoneyWithCurrency(value)
    : returnWhenEmpty
}
