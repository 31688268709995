import Clarity from '@microsoft/clarity'
import type { GetMeResponseV2 } from '~/types/apiResponse/me.response'

export function initPendo(
  personId: string,
  org: GetMeResponseV2['organisations'][number],
  profile: GetMeResponseV2,
) {
  pendo.initialize({
    visitor: {
      id: personId,
      email: profile.work_email_address,
      full_name: `${profile.given_name} ${profile.family_name}`,
    },
    account: {
      id: org.trading_name || org.registered_name || org.id,
    },
  })
}

export function initAndSetupClarity(profile: GetMeResponseV2) {
  const { CLARITY } = useRuntimeConfig().public
  const { family_name, given_name, work_email_address } = profile
  const fullName = `${given_name} ${family_name}`

  Clarity.init(CLARITY)

  Clarity.identify(
    work_email_address,
    '',
    '',
    `${fullName} - ${work_email_address}`,
  )
}

export function setTagsOnClarity(
  profile: GetMeResponseV2,
  organisation: (typeof profile)['organisations'][number],
) {
  if (!organisation) return

  const { family_name, given_name, work_email_address } = profile
  const { trading_name, id, registered_name } = organisation

  const companyName = trading_name || registered_name

  const setClarityTag = (key: string, value?: unknown) => {
    if (value) Clarity.setTag(key, value.toString())
  }

  setClarityTag('first name', given_name)
  setClarityTag('last name', family_name)
  setClarityTag('email', work_email_address)
  setClarityTag('company id', id)
  setClarityTag('company name', companyName)
}
