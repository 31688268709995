export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"children":"(function(apiKey){\n          (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];\n          v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){\n              o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);\n              y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';\n              z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');\n      })('8ca966d5-ff24-4e49-4d82-e5770f04f26a')"},{"innerHTML":"window.pipedriveLeadboosterConfig = {\n            base: 'leadbooster-chat.pipedrive.com',\n            companyId: 10109700,\n            playbookUuid: 'af73a058-c6c7-4e35-8b65-a9a039936a9c',\n            version: 2,\n          }\n          ;(function () {\n            var w = window\n            if (w.LeadBooster) {\n              console.warn('LeadBooster already exists')\n            } else {\n              w.LeadBooster = {\n                q: [],\n                on: function (n, h) {\n                  this.q.push({ t: 'o', n: n, h: h })\n                },\n                trigger: function (n) {\n                  this.q.push({ t: 't', n: n })\n                },\n              }\n            }\n          })()"},{"src":"https://leadbooster-chat.pipedrive.com/assets/loader.js","async":true}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000